export function getServiceRoot(location: Location): string {
    const { host, hostname, protocol, origin } = location;

    if (hostname.endsWith('.hedgeserv.com') || hostname.endsWith('.hsuat.com')) {
        return `${protocol}//access.${host.split('.').slice(-2).join('.')}`;
    }

    return origin;
}

export function getPublicApiEnvironment(location: Location): string {
    if (location.hostname.includes('poc-rnd') || location.hostname.includes('localhost')) {
        return 'public-api-edge';
    }

    return 'public-api';
}

export function getEnvironment(location: Location): string {
    if (location.hostname.includes('poc-rnd.hsuat')) {
        return 'rnd';
    }

    if (location.hostname.includes('app.hsuat')) {
        return 'uat';
    }

    if (location.hostname.includes('app-tst.hedgeserv')) {
        return 'tst';
    }

    if (location.hostname.includes('app.hedgeserv')) {
        return 'prod';
    }

    return 'local';
}

export function getApmHost(hostname: string): string {
    if (hostname.includes('hsuat.com')) {
        return 'https://access.hsuat.com';
    }

    if (hostname.includes('hedgeserv.com')) {
        return 'https://access.hedgeserv.com';
    }

    return '';
}
