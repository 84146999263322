import { NgModule } from '@angular/core';
import { LoadChildrenCallback, RouterModule, Routes } from '@angular/router';

import { canAccessFieldBuilderGuard } from './shared/guards/can-access-field-builder/can-access-field-builder.guard';
import { canAccessNamedQueryGuard } from './shared/guards/can-access-named-query/can-access-named-query.guard';
import { WINDOW_TOKEN } from './shared/services/entitlements.service';

export const routes: Routes = [
    {
        path: ':clientCode/queries',
        loadChildren: (
            async () => import('./named-query/named-query.module').then((m) => m.NamedQueryModule)
        ) as LoadChildrenCallback,
        canMatch: [canAccessNamedQueryGuard],
    },
    {
        path: ':clientCode/fields',
        loadChildren: (
            async () => import('./field-builder/field-builder.module').then((m) => m.FieldBuilderModule)
        ) as LoadChildrenCallback,
        canMatch: [canAccessNamedQueryGuard, canAccessFieldBuilderGuard],
    },
    {
        path: ':clientCode',
        redirectTo: ':clientCode/queries/new',
        pathMatch: 'prefix',
    },
    { path: '**' , redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [
        { provide: WINDOW_TOKEN, useValue: window },
    ],
})
export class AppRoutingModule {}
