<cc-tool-tip></cc-tool-tip>
<cc-modal-layer></cc-modal-layer>
<cc-nav-bar
    [multiClientApp]="false"
    [clientCode]="clientCode"
    [appDisplayName]="'NQS'"
    [versions]="{ version }">
</cc-nav-bar>

<cc-notifications-container [options]="{ autoDismiss: false }"></cc-notifications-container>

<div class="app-content-wrapper">
    <cc-tab-routing level="main" [tabDescriptors]="tabDescriptors"></cc-tab-routing>
    <router-outlet></router-outlet>
</div>
