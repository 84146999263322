import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ApmErrorHandler,
    ApmModule,
    ApmService,
} from '@elastic/apm-rum-angular';
import { CcLayoutModule } from '@hs/ui-core-layout';
import { CcNavbarModule } from '@hs/ui-core-navbar';
import { CcNotificationsModule } from '@hs/ui-core-notifications';
import { CcPresentationModule } from '@hs/ui-core-presentation';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpErrorInterceptor } from './error-interceptor/http-error.interceptor';
import { LocalErrorHandler } from './error-interceptor/local-error-handler';
import { WINDOW } from './shared/window';
import { VersionService } from './version.service';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        ApmModule,
        BrowserAnimationsModule,
        BrowserModule,
        CcLayoutModule,
        CcNavbarModule,
        CcNotificationsModule,
        CcPresentationModule,
    ],
    providers: [
        ApmService,
        { provide: WINDOW, useFactory: (): Window & typeof globalThis => window },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        ApmErrorHandler,
        { provide: ErrorHandler, useClass: LocalErrorHandler },
        provideHttpClient(withInterceptors([httpErrorInterceptor])),
        VersionService,
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
    constructor(service: ApmService) {
        service.init({
            serverUrl: `${environment.apm.host}/apm/`,
            environment: environment.apm.environment,
            centralConfig: false,
            serviceName: 'Named Query',
            active: environment.apm.active,
            sendCredentials: true,
        });
    }
}
