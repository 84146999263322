import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable()
export class VersionService {
    constructor(private readonly http: HttpClient) { }

    getVersion(): Observable<string> {
        return this.http.get<{ build: { version: string } }>(`${environment.api}/monitor`).pipe(
            map(({ build: { version } }) => version),
        );
    }
}
