import { Component, OnDestroy, OnInit } from '@angular/core';
import { TabDescriptor } from '@hs/ui-core-layout';
import { Subscription, combineLatest, switchMap } from 'rxjs';

import { UserEntitlements } from './shared/models/entitlements';
import { EntitlementsService } from './shared/services/entitlements.service';
import { RoutingService } from './shared/services/routing.service';
import { VersionService } from './version.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    clientCode: string | undefined;
    tabDescriptors: TabDescriptor[] = [];

    private url = '';

    protected version: string = '';
    private versionSubscription: Subscription | undefined;

    constructor(
        private readonly routingService: RoutingService,
        private readonly entitlementsService: EntitlementsService,
        private readonly versionService: VersionService,
    ) {}

    ngOnInit(): void {
        combineLatest([this.routingService.clientCode$, this.routingService.url$]).pipe(switchMap(([clientCode, url]) => {
            this.clientCode = clientCode;
            this.url = url.substring(1);
            return this.entitlementsService.getEntitlements(clientCode);
        })).subscribe((userEntitlements: UserEntitlements) => {
            // CC doesn't work when reassigning an array,
            // so we need to empty the one that we provide initially
            while (this.tabDescriptors.length) {
                this.tabDescriptors.pop();
            }

            this.setTabDescriptors(userEntitlements);
        });

        this.routingService.isRedirectedToRoot$.subscribe((isRedirectedToRoot) => {
            if (isRedirectedToRoot) {
                this.clientCode = undefined;
                this.tabDescriptors = [];
            }
        });

        this.versionSubscription = this.versionService.getVersion().subscribe({
            next: (v) => {
                this.version = v;
            },
        });
    }

    ngOnDestroy(): void {
        this.versionSubscription?.unsubscribe();
    }

    private setTabDescriptors(userEntitlements: UserEntitlements): void {
        this.tabDescriptors
            .push({ value: this.url.includes('queries') ? `${this.url}` : `${this.clientCode}/queries/new`, text: 'QUERY MANAGER' });

        if (userEntitlements.canManageCustomFields) {
            this.tabDescriptors
                .push({ value: this.url.includes('fields') ? `${this.url}` : `${this.clientCode}/fields/new`, text: 'FIELD BUILDER' });
        }
    }
}
