import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { filter, ReplaySubject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RoutingService {
    readonly clientCode$ = new ReplaySubject<string>(1);
    readonly id$ = new ReplaySubject<string>(1);
    readonly isRedirectedToRoot$ = new ReplaySubject<boolean>(1);
    readonly url$ = new ReplaySubject<string>(1);

    private currentClientCode: string | null = null;
    private currentId: string | null = null;

    private readonly routeSubscriptions: Subscription[] = [];

    constructor(private readonly route: ActivatedRoute, private readonly router: Router) {
        // The routing tree seems to be rebuilt on certain router events, so we'll just re-subscribe every time we navigate
        this.router.events
            .pipe(filter((event: Event) => event instanceof NavigationEnd))
            .subscribe((event) => this.onRouterEventsSubscription(event as NavigationEnd));
    }

    private onRouterEventsSubscription(event: NavigationEnd): void {
        if (event.urlAfterRedirects === '/') {
            this.currentClientCode = null;
            this.isRedirectedToRoot$.next(true);
        } else {
            this.routeSubscriptions.forEach((s) => s.unsubscribe());
            this.routeSubscriptions.splice(0); // Empty the array
            this.subscribe(this.route.root);
            this.url$.next(this.router.url);
        }
    }

    private subscribe(route: ActivatedRoute): void {
        this.routeSubscriptions.push(route.params.subscribe(this.updateState.bind(this)));
        route.children.forEach((r) => this.subscribe(r));
    }

    private updateState(params: Params): void {
        if (Object.hasOwn(params, 'clientCode')) {
            const newClientCode = params['clientCode'];
            // The whole app assumes it should completely reinit itself when the client code changes
            // therefore, we can only emit a clientCode when it is actually changed
            if (newClientCode !== this.currentClientCode) {
                this.currentClientCode = newClientCode;
                this.clientCode$.next(newClientCode);
            }
        }

        if (Object.hasOwn(params, 'id')) {
            const newId = params['id'];
            if (newId !== this.currentId) {
                this.currentId = newId;
                this.id$.next(newId);
            }
        }
    }
}
